import React from 'react'
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
function Companies() {
    return (
        <>
            <div className="our-clients my-1">
            <Container>
              <Row>
                <Col className="ml-auto d-flex justify-content-center" md="2">
                  <img
                    alt="..."
                    src={
                      require("assets/BG/PartnersLogo/atlas.jpg")
                        .default
                    }
                  ></img>
                </Col>
                <Col md="2" className='d-flex justify-content-center'>
                  <img
                    alt="..."
                    src={
                      require("assets/BG/PartnersLogo/gerber.jpg")
                        .default
                    }
                  ></img>
                </Col>
                <Col md="2" className='d-flex justify-content-center'>
                  <img
                    alt="..."
                    src={
                      require("assets/BG/PartnersLogo/mr.jpg")
                        .default
                    }
                  ></img>
                </Col>
                <Col className="mr-auto d-flex justify-content-center" md="2">
                  <img
                    alt="..."
                    src={
                      require("assets/BG/PartnersLogo/oerlikon.jpg")
                        .default
                    }
                  ></img>
                </Col>
                <Col className="ml-auto d-flex justify-content-center" md="2">
                  <img
                    alt="..."
                    src={
                      require("assets/BG/PartnersLogo/trutz.jpg")
                        .default
                    }
                  ></img>
                </Col>
                <Col md="2" className='d-flex justify-content-center'>
                  <img
                    alt="..."
                    src={
                      require("assets/BG/PartnersLogo/welker.jpg")
                        .default
                    }
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>
        </>
    )
}

export default Companies
