import React from 'react'
import Navbar from "components/Navbars/ScrollTransparentNavbar.js";
import Feature from 'pages/about/feature'
import Service from 'pages/about/service'
import Companies from 'pages/home/Companies';
import Content from 'pages/about/Content'
import Rellax from "rellax";
import Helmet from 'SEOHelmet/CustomHelmet'

function Index() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        return function cleanup() {
          document.body.classList.remove("index-page");
          document.body.classList.remove("sidebar-collapse");
        };
      });
    React.useEffect(() => {
      document.body.classList.add("presentation-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  
      // initialise Rellax for this page
      if (window.innerWidth >= 991) {
        setTimeout(function () {
          new Rellax(".rellax", {
            center: true,
          });
        }, 5000);
        new Rellax(".rellax-header");
        new Rellax(".rellax-text");
      }
  
      // we need to add a script for the github buttons
      let script = document.createElement("script");
      script.src = "https://buttons.github.io/buttons.js";
      script.id = "github-buttons-script-id";
      document.body.appendChild(script);
  
      return function cleanup() {
        document.body.classList.remove("presentation-page");
        document.body.classList.remove("sidebar-collapse");
  
        // we need to remove the script when we change the page
        script.parentNode.removeChild(script);
      };
    });
    return (
        <>
        <Helmet title='BS-Textile || About' content='About Us'/>
          <Navbar/>
          <div className="wrapper">
          <Content/>
          <Feature/>
          <Service/>
          <Companies/>
          </div>
        </>
    )
}

export default Index
