import React from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Container,
    Row,
    Col,
  } from "reactstrap";
  import Navbar from "components/Navbars/ScrollTransparentNavbar.js";
  import Footer from 'container/footer';
  import Helmet from 'SEOHelmet/CustomHelmet'
  import { useTranslation } from 'react-i18next';
  
function Index() {
  React.useEffect(() => {
    // document.body.classList.add("index-page");
    // document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  const { t } = useTranslation();
    return (
        <>
        <Helmet title='BS-Textile || Contact' content='Contact Us'/>
        <Navbar/>
         <div className="menu">
          <div
          className="pricing-3 section-image"
          id="pricing-3"
          style={{
            backgroundImage:
              "url(" + require("assets/BG/rasm/1.jpg").default + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{t('Contact.1')}</h2>
                {/* <h4 className="description">
                  You have Free Unlimited Updates on each package.
                </h4> */}
                <div className="section-space"></div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="4">
                <Card className="card-pricing">
                  <CardBody>
                    <h5 className="category">{t('Contact.2')}</h5>
                    <img alt='...' src="https://img.icons8.com/ios/64/000000/new-contact.png"/>
                    <CardTitle tag="h1">
                      <small>{t('Contact.3')}</small>
                    </CardTitle>
                    <p>
                    <a  href='tel:+998903030512'>+(998)90 303 0512</a><br/>
                    {t('Contact.4')}: 8:00-17:00<br />
                    {t('Contact.14')}: 12:00-14:00 <br/>
                    {t('Contact.15')}: {t('Contact.13')}
                    </p>
                    <hr/>
                    <Button
                      className="btn-round"
                      // color="info"
                      style={{backgroundColor:'#D2A264'}}
                      href='tel:+998903030512'
                    >
                      {t('Contact.5')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="4">
                <Card
                  className="card-pricing card-raised"
                  // data-background-color="blue"
                  style={{backgroundColor:'#D2A264'}}
                >
                  <CardBody>
                    <h5 className="category"     style={{color:'#FFF'}}>{t('Contact.6')}</h5>
                    <img alt='...' src="https://img.icons8.com/ios/64/ffffff/globe--v1.png" />
                    <CardTitle tag="h1" style={{color:'#FFF'}}>
                      <small>{t('Contact.7')}</small>
                    </CardTitle>
                    <Button className="btn-neutral btn-icon btn-round">
                    <img alt='...' src="https://img.icons8.com/material-rounded/24/4a90e2/facebook-circled--v1.png"/>
                    </Button>
                    <Button className="btn-neutral btn-icon btn-round">
                    <img alt='...' src="https://img.icons8.com/material-rounded/24/4a90e2/instagram-new.png"/>
                    </Button>
                    <Button className="btn-neutral btn-icon btn-round">
                    <img alt='...' src="https://img.icons8.com/material-rounded/24/4a90e2/whatsapp.png"/>
                    </Button>
                    <Button className="btn-neutral btn-icon btn-round">
                    <img alt='...' src="https://img.icons8.com/pastel-glyph/24/4a90e2/email--v1.png"/>
                    </Button>
                  </CardBody>
                  <CardFooter>
                      <Button
                      className="btn-neutral btn-round"
                      // color="info"
                      style={{color:'#D2A264'}}
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      {t('Contact.8')}
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col className="ml-auto" md="4">
                <Card className="card-pricing">
                  <CardBody>
                    <h5 className="category">{t('Contact.9')}</h5>
                    <img alt='...' src="https://img.icons8.com/ios-filled/64/000000/location-update.png"/>
                    <CardTitle tag="h1">
                      <small>{t('Contact.10')}</small>
                    </CardTitle>
                    <p>
                    {/* 1, Chinabad street,<br/>
                    Altinkul district,<br/>
                    Andijan, Uzbekistan,<br/>
                    171000 */}
                    {t('Contact.11')}
                    </p>
                    <hr/>
                    <Button
                      className="btn-round"
                      // color="info"
                      style={{backgroundColor:'#D2A264'}}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://maps.google.com/maps?ll=40.381574,71.810302&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=11393172928085683297"
                    >
                      {t('Contact.12')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.230925969901!2d71.80811361539484!3d40.38157427936921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38bb8542bb44b23f%3A0x9e1cae6a1b24ec61!2sBS%20Textile%20Fargona!5e0!3m2!1sen!2s!4v1629693670319!5m2!1sen!2s" 
    width="100%" height="400" frameborder="0" title='location'></iframe>
        <Footer/>
        </div>
        </>
    )
}

export default Index
