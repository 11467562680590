import React from "react";
import { Container, Row, Col } from "reactstrap";
// core components
import mediumZoom from 'medium-zoom'
import ImageZoom from './ImgZoom'

function Sections() {
  const zoom = React.useRef(mediumZoom())

  return (
    <>
    <div className="section">
       <div className="about-office">
            <Container>
              <Row>
              <Col md="6">
              <ImageZoom
                 src={require("assets/BG/Gallery/11.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="6">
                <ImageZoom
                 src={require("assets/BG/Gallery/12.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="4">
                <ImageZoom
                 src={require("assets/BG/Gallery/1.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="4">
                <ImageZoom
                  src={require("assets/BG/Gallery/2.jpg").default}
                  alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="4">
                <ImageZoom
                 src={require("assets/BG/Gallery/3.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="6">
                <ImageZoom
                 src={require("assets/BG/Gallery/4.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="6">
                <ImageZoom
                 src={require("assets/BG/Gallery/5.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="4">
                <ImageZoom
                 src={require("assets/BG/Gallery/6.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="4">
                <ImageZoom
                 src={require("assets/BG/Gallery/12.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="4">
                <ImageZoom
                 src={require("assets/BG/Gallery/8.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="6">
                <ImageZoom
                 src={require("assets/BG/Gallery/9.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
                <Col md="6">
                <ImageZoom
                 src={require("assets/BG/Gallery/10.jpg").default}
                 alt="BS Textile Fargona"
                  zoom={zoom.current}
                  background="#fff"/>
                </Col>
              </Row>
            </Container>
          </div>
          </div>
    </>
  );
}

export default Sections;
